<template>
  <div>
    <el-form :inline="true" :model="searchData">
      <el-form-item><el-input v-model="searchData.name" placeholder="用户名"></el-input></el-form-item>
      <el-form-item><el-input v-model="searchData.create_time" placeholder="创建时间"></el-input></el-form-item>
      <el-form-item><el-input v-model="searchData.last_login_ip" placeholder="最后登录ip"></el-input></el-form-item>
      <el-form-item><el-button type="default" @click="handleSearch">查询</el-button></el-form-item>
    </el-form>
    <el-form :inline="true">
      <el-form-item><el-button type="primary" @click="handleSubmit">新增用户</el-button></el-form-item>
      <el-form-item><el-button type="danger" @click="handleBatchDelete" :disabled="multipleSelection.length==0">批量删除</el-button></el-form-item>
    </el-form>
    <el-table
      :data="UserList"
      stripe
      @selection-change="handleSelectionChange"
      style="width: 100%">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="id"
        label="Id">
      </el-table-column>
      <el-table-column
        prop="name"
        label="用户名">
      </el-table-column>
      <el-table-column
        prop="secret"
        label="密钥">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="update_time"
        label="更新时间">
      </el-table-column>
      <el-table-column
        prop="last_login_time"
        label="最后登录时间">
      </el-table-column>
      <el-table-column
        prop="last_login_ip"
        label="最后登录IP">
      </el-table-column>
      <el-table-column
        width="295"
        label="操作">
        <!--
        <template> 上使用特殊的 slot-scope attribute，可以接收传递给插槽的 prop
          对象会作为 scope 变量存在于 <template> 作用域中
         -->
        <template slot-scope="scope">
          <el-button type="warning"  icon="el-icon-edit" @click="update(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="remove(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页 -->
    <el-pagination
      background
      :page-size="pageInfo.pageSize"
      layout="total,prev,pager,next,jumper"
      @current-change="handleCurrentChange"
      :total="pageInfo.total">
    </el-pagination>
    <!--编辑添加子组件-->
    <user-add-update :isVisible="isVisible" :title="title" :updateId="updateId" @ChangeVisible="handleChangeVisible" @query="query"></user-add-update>
  </div>
</template>

<script>
//导入用户添加编辑组件作为当用组件的子组件
import UserAddUpdate from "@/views/user/UserAddUpdate";
export default {
  name: "User",
      data(){
        return {
          UserList:[
          ],
          searchData:{
             name:"",
             last_login_ip:"",
             create_time:"",
          },
          total:0,
          pageInfo:{
            //当前页
            currentPage:1,
            //每页显示条数
            pageSize:5,
            //总记录数
            total:0,
          },
          multipleSelection: [],
          isVisible:false,
          title:"",
          updateId:0,
        }
      },
      methods:{
        query(){
          this.$http({
            method: 'post',
            url: '/sys_user/list',
            data: {
              name: this.searchData.name,
              last_login_ip: this.searchData.last_login_ip,
              page:this.pageInfo.currentPage,
              size:this.pageInfo.pageSize,
            },
          }).then(res=>{
            if (res.data.code==0) {
              this.UserList = res.data.data.list
              this.pageInfo.total = res.data.data.total
            }
          }).catch(err=>{
            console.log(err)
          })
        },
        handleCurrentChange(page){
          this.pageInfo.currentPage = page
          this.query()
        },
        handleSearch(){
          this.pageInfo.currentPage = 1
          this.query()
        },
        handleSubmit(){
          this.isVisible = true
          this.title = "新增"
        },
        handleSelectionChange(val){
          this.multipleSelection = val
          console.log(this.multipleSelection)
        },
        update(id){
          //this.$router.push({name:"UserUpdate",params:{id:id}})
          this.updateId = id
          this.isVisible = true
          this.title = "编辑"
        },
        remove(id){
          this.$confirm('确定要删除?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$http({
              method: 'post',
              url: '/sys_user/delete',
              data: {
                id: id,
              },
            }).then(res=>{
              if (res.data.code==0) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.query()
              }else{
                this.$message({
                  type: 'warning',
                  message: '删除失败!'
                });
              }
            }).catch(err=>{
              console.log(err)
            })
          }).catch(err=>{
            console.log(err)
          })
        },
        handleBatchDelete(){
          let ids = this.multipleSelection.map(item=>item.id)
          console.log(ids)
        },
        handleChangeVisible(){
          console.log("recive")
          this.isVisible = false
        }
      },
      //生命周期函数,页面加载完毕拉取后台数据
      mounted() {
        this.query()
      },
     components:{
       UserAddUpdate
     }
}
</script>

<style scoped>
table{
  width:100%;
}
</style>
